<template>
	<TourContent business-type="doctor" />
</template>

<script async>
import TourContent from '@/views/tour/components/TourContent.vue'

export default {
	components: {
		TourContent
	}
}
</script>
